import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import '@fortawesome/fontawesome-free/css/all.min.css';

const SupportPage = () => (
  <Layout>
    <SEO title="Kit támogassak? Kinek adományozzak?" />
    <h1>Kit támogassak? Kinek adományozzak?</h1>
    <p>Az általunk megadott listából ezt most könnyen eldöntheted.</p>
    
    <div className="content-wrapper">
        <h3 className="health-icon"><i className="fas fa-plus"></i> Egyesületek</h3>
        <div className="header-wrapper">
            <div className="cardify-content-wrapper">
                <a href="http://voroskereszt.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">Magyar Vöröskereszt</h5>
                    <p className="cardify-content-alt">Nemzeti humanitárius társadalmi szervezet.</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                </a>
                <a href="https://orokbefogadokegyovit.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">Örökbe fogadok Egy Ovit</h5>
                    <p className="cardify-content-alt">Országos támogatási egyesület.</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                </a>
            </div>
        </div>
    </div>

    <div className="content-wrapper">
        <h3 className="child-icon"><i className="fas fa-child"></i> Gyermekotthonok</h3>
        <div className="header-wrapper">
            <div className="cardify-content-wrapper">
                <a href="http://www.cseppgyermek.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">Cseppkő Gyermekotthoni Központ</h5>
                    <p className="cardify-content-alt">Budapesti gyermekotthon.</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                </a>
                <a href="http://www.hgyermekotthon.axelero.net/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">Hűvösvölgyi Gyermekotthon</h5>
                    <p className="cardify-content-alt">Budapesti gyermekotthon.</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                </a>
                <a href="http://waltnerkarolyotthon.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">Dr. Waltner Károly Otthon</h5>
                    <p className="cardify-content-alt">Szegedi gyermekotthon.</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Szeged</p>
                </a>
                <a href="http://www.remenysugarotthon.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">Debreceni Reménysugár Gyermekotthon</h5>
                    <p className="cardify-content-alt">Debreceni gyermekotthon.</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Debrecen</p>
                </a>
                <a href="http://www.bmgyk.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                    <h5 className="main-content-card-title">Baranya Megyei Gyermekvédelmi Központ</h5>
                    <p className="cardify-content-alt">Pécsi gyermekotthon.</p>

                    <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Pécs</p>
                </a>
            </div>
        </div>
    </div>

    <div className="content-wrapper">
            <h3 className="animal-icon"><i className="fas fa-dog"></i> Menhelyek</h3>
            <div className="header-wrapper">
                <div className="cardify-content-wrapper">
                    <a href="http://noeallatotthon.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                        <h5 className="main-content-card-title">Noé Állatotthon</h5>
                        <p className="cardify-content-alt">Budapesti állatmenhely.</p>

                        <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                    </a>
                    <a href="https://www.tappancs.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                        <h5 className="main-content-card-title">Tappancs Tanya</h5>
                        <p className="cardify-content-alt">Szegedi állatmenhely.</p>

                        <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Szeged</p>
                    </a>
                </div>
            </div>
        </div>

        <div className="content-wrapper">
            <h3 className="other-icon"><i className="fas fa-question"></i> Egyéb</h3>
            <div className="header-wrapper">
                <div className="cardify-content-wrapper">
                    <a href="https://szurkolokazallatokert.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                        <h5 className="main-content-card-title">Szurkolók Az Állatkínzás Ellen</h5>
                        <p className="cardify-content-alt">Állatok megmentése és a felelős állattartás megtanítása, betartatása, ellenőrzése.</p>

                        <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                    </a>

                    <a href="https://tavoktatas2020.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                        <h5 className="main-content-card-title">OKTONDI</h5>
                        <p className="cardify-content-alt">Online oktatási segédlet diákoknak.</p>

                        <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                    </a>
                </div>
            </div>
        </div>
  </Layout>
)

export default SupportPage
